<template>
  <v-container>
    <h1>Protected Route</h1>
    <p>This page is protected.</p>
    <p>The user must be logged in to access this page.</p>
  </v-container>
</template>

<script>
  export default {
    name: "Protected",
    methods: {
    }
  }
</script>

<style scoped>
</style>